<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div>
        <div class="md-layout">
          <div class="md-layout-item md-size-30 md-small-size-100">
            <form-uploader
              class="imgSelector"
              image-type
              @input="imageSelected"
              @error="fireError"
            >
              <div class="picture-container">
                <div class="picture">
                  <img
                    :src="profileFileURL || avatar"
                    class="picture-src"
                  >
                </div>

                <h6 class="description mt-3">
                  {{ $lang('first_step.fields.picture') }}
                </h6>
              </div>
            </form-uploader>
            <p
              class="text-center small-text imgSelectorDetails"
              v-html="$lang('first_step.fields.picture_info')"
            />
          </div>
          <div class="md-layout-item md-size-70 md-small-size-100">
            <p>{{ $lang('first_step.fields.name_info') }}</p>
            <form-text
              v-model="form.details.first_name"
              icon="face"
              rules="required"
              :label="$lang('first_step.fields.name')"
              asterisk
              :disabled="form.student_id !== null"
            />

            <form-text
              v-model="form.details.last_name"
              :label="$lang('first_step.fields.surname')"
              rules="required"
              icon="record_voice_over"
              asterisk
              :disabled="form.student_id !== null"
            />

            <CountriesAutocomplete
              v-model="form.details.country_id"
              :label="$lang('first_step.fields.nationality')"
              rules="required"
              asterisk
            />

            <form-radio
              v-model="form.details.gender"
              :label="$lang('first_step.fields.gender')"
              asterisk
              name="gender"
              :options="$lang('first_step.fields.gender_options')"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <form-date
              v-model="form.details.date_of_birth"
              name="birth_date"
              :label="$lang('first_step.fields.birth_date')"
              rules="required"
              asterisk
            />

            <form-text
              v-model="form.email"
              icon="email"
              name="email"
              :label="$lang('first_step.fields.email')"
              asterisk
              disabled
            />

            <form-text
              v-model="form.details.phone"
              icon="phone"
              name="phone"
              label="Phone"
              asterisk
              rules="required"
            />

            <form-text
              v-model="form.details.emergency_contact_name"
              icon="person_outline"
              :label="$lang('first_step.fields.emergency_contact_name')"
              asterisk
            />
            <form-text
              v-model="form.details.emergency_contact"
              icon="contact_page"
              :label="$lang('first_step.fields.emergency_contact')"
              asterisk
            />
          </div>
        </div>

        <!-- Flight -->
        <div
          class="md-layout-item md-size-100"
        >
          <h5
            v-if="form.details.flight_status === 'have_ticket'"
          >
            {{ $lang('first_step.fields.flight') }}
          </h5>
          <FormSelect
            :value="form.details.flight_status"
            icon="airplanemode_active"
            :label="$lang('first_step.fields.flight_status')"
            asterisk
            :options="$lang('first_step.fields.flight_status_options')"
            @input="onChangeFlightStatus"
          />
          <template
            v-if="form.details.flight_status === 'have_ticket'"
          >
            <FormText
              v-model="form.details.flight.flight_code"
              :label="$lang('first_step.fields.flight_code')"
              asterisk
              icon="money"
            />
            <FormDate
              v-model="form.details.flight.departure_date"
              date-time
              :label="$lang('first_step.fields.flight_departure_date')"
              asterisk
            />
            <FormDate
              v-model="form.details.flight.arrival_date"
              date-time
              :label="$lang('first_step.fields.flight_arrival_date')"
              asterisk
            />
            <FormText
              v-model="form.details.flight.airline"
              icon="airplanemode_active"
              :label="$lang('first_step.fields.flight_airline')"
              asterisk
            />
            <div class="d-flex">
              <FormUploader
                class="pb-4"
                @input="onFlightUploaded"
                @error="fireError"
              >
                <md-button class="md-info">
                  <md-icon>file_present</md-icon>
                  {{ $lang('first_step.fields.flight_voucher_button') }}
                  <span class="asterisk"> *</span>
                </md-button>

                <br>
                <small>{{ $lang('first_step.fields.flight_voucher_button_info') }}</small>
                <br>
                <small
                  v-if="form.flight_file"
                  class="text-success font-weight-bold"
                >
                  {{ $lang('first_step.fields.flight_voucher_file_info') }}
                  {{ form.flight_file.name }}
                </small> <br>
              </FormUploader>

              <div
                v-if="flightFileURL"
              >
                <md-button
                  :href="flightFileURL"
                  target="_blank"
                  class="md-primary"
                >
                  {{ $lang('first_step.fields.flight_voucher_download') }}
                  <md-icon>download</md-icon>
                </md-button>
              </div>
            </div>
          </template>
        </div>

        <!-- ENTRY VISA -->
        <md-switch
          v-model="entry_visa"
          class="text-black"
        >
          {{ $lang('first_step.fields.entry_visa_switch') }}
        </md-switch>
        <div
          v-if="entry_visa"
          class="d-flex"
        >
          <FormUploader
            class="pb-4"
            @input="onEntryVisaFileUploaded"
            @error="fireError"
          >
            <md-button class="md-info">
              <md-icon>file_present</md-icon>
              {{ $lang('first_step.fields.entry_visa_button') }}
              <span class="asterisk"> *</span>
            </md-button>

            <br>
            <small>{{ $lang('first_step.fields.entry_visa_button_info') }}</small>
            <br>
            <small
              v-if="form.entry_visa_file && form.entry_visa_file.name"
              class="text-success font-weight-bold"
            >
              {{ $lang('first_step.fields.file_to_upload') }} {{ form.entry_visa_file.name }}
            </small> <br>
          </FormUploader>

          <div
            v-if="entryVisaFileURL"
          >
            <md-button
              :href="entryVisaFileURL"
              target="_blank"
              class="md-primary"
            >
              {{ $lang('first_step.fields.entry_visa_download') }}
              <md-icon>download</md-icon>
            </md-button>
          </div>
        </div>

        <!-- GNIB -->
        <md-switch
          v-model="no_gnib"
          class="text-black"
        >
          {{ $lang('first_step.fields.irp_switch') }}
        </md-switch>
        <div
          v-if="!form.details.no_gnib"
          class="md-layout-item md-size-100"
        >
          <h5>{{ $lang('first_step.fields.irp') }}</h5>
          <FormNumber
            v-model="form.details.gnib.reg_number"
            icon="tag"
            :label="$lang('first_step.fields.irp_reg_number')"
            asterisk
          />
          <FormDate
            v-model="form.details.gnib.issue_date"
            :label="$lang('first_step.fields.irp_issue_date')"
            asterisk
          />
          <FormDate
            v-model="form.details.gnib.expiration_date"
            :label="$lang('first_step.fields.irp_exp_date')"
            asterisk
          />
          <FormSelect
            v-model="form.details.gnib.stamp"
            icon="approval"
            :label="$lang('first_step.fields.irp_stamp')"
            asterisk
            :options="$lang('first_step.fields.irp_stamp_options')"
          />
        </div>

        <!-- Passport -->

        <md-switch
          v-model="no_passport"
          class="text-black"
        >
          {{ $lang('first_step.fields.passport_switch') }}
        </md-switch>
        <div
          v-if="!form.details.no_passport && form.details.passports.length"
          class="md-layout"
        >
          <div class="md-layout-item md-size-100">
            <h5 class="withTooltip">
              {{ $lang('first_step.fields.passport') }}

              <div
                id="tooltipImgAnchor"
                class="md-sm d-inline pointer"
                @mouseover="showImgTooltip = true"
                @mouseout="showImgTooltip = false"
              >
                <md-icon>help</md-icon>
              </div>

              <FadeTransition
                :duration="200"
                mode="out-in"
              >
                <div
                  v-if="showImgTooltip"
                  class="tooltipImg"
                >
                  <img src="/img/passport_help.png">
                </div>
              </FadeTransition>
            </h5>
          </div>

          <div class="md-layout-item">
            <form-text
              v-model="form.details.passports[0].number"
              icon="card_travel"
              name="passport"
              :label="$lang('first_step.fields.passport_number')"
              asterisk
            />

            <CountriesAutocomplete
              v-model="form.details.passports[0].country_id"
              :label="$lang('first_step.fields.passport_country')"
              asterisk
            />

            <form-date
              v-model="form.details.passports[0].issue_date"
              name="passport_issue"
              :label="$lang('first_step.fields.passport_issued_date')"
              asterisk
            />

            <form-date
              v-model="form.details.passports[0].expiration_date"
              name="passport_expiration"
              :label="$lang('first_step.fields.passport_exp_date')"
              asterisk
            />

            <div class="d-flex flex-column-sm">
              <form-uploader
                v-model="form.details.passports[0].file"
                @error="fireError"
                @input="$forceUpdate()"
              >
                <md-button class="md-success">
                  {{ $lang('first_step.fields.passport_button') }}
                </md-button>
                <br>
                <small>{{ $lang('first_step.fields.passport_button_info') }}</small>
                <br>
                <small
                  v-if="form.details.passports[0].file"
                  class="text-success font-weight-bold"
                >
                  {{ $lang('first_step.fields.file_to_upload') }}
                  {{ form.details.passports[0].file.name }}
                </small> <br>
              </form-uploader>

              <div
                v-if="form.passport_file && form.passport_file.url"
              >
                <md-button
                  :href="form.passport_file.url"
                  target="_blank"
                  class="md-primary"
                >
                  {{ $lang('first_step.fields.passport_download') }}
                </md-button>
              </div>
            </div>
          </div>
        </div>

        <!-- Address -->
        <div
          v-if="form.details.addresses.length"
          class="md-layout"
        >
          <div
            class="md-layout-item md-size-100"
          >
            <h5>{{ $lang('first_step.fields.address') }}</h5>
          </div>

          <div
            class="md-layout-item"
          >
            <form-select
              v-model="form.details.addresses[0].type"
              name="address_type1"
              icon="local_offer"
              :label="$lang('first_step.fields.address_type')"
              asterisk
              :help-text="$lang('first_step.fields.address_help_text')"
              :options="$lang('first_step.fields.address_types')"
            />
            <form-text
              v-model="form.details.addresses[0].address"
              icon="my_location"
              name="address1"
              :label="$lang('first_step.fields.address')"
              asterisk
            />
            <form-text
              v-model="form.details.addresses[0].city"
              name="address_city1"
              icon="local_offer"
              :label="$lang('first_step.fields.address_city')"
              asterisk
            />
            <form-text
              v-model="form.details.addresses[0].county"
              name="address_county1"
              icon="local_offer"
              :label="$lang('first_step.fields.address_county')"
            />
            <CountriesAutocomplete
              v-model="form.details.addresses[0].country_id"
              :label="$lang('first_step.fields.address_country')"
              asterisk
            />
            <form-text
              v-model="form.details.addresses[0].zip_code"
              name="address_zip_code1"
              icon="local_offer"
              :label="$lang('first_step.fields.address_zip_code')"
            />
          </div>
        </div>
      </div>
    </form>

    <image-cropper
      v-if="showCropper"
      :img="profileFileURL"
      :img-type="imageType"
      @imageCropped="imageCropped"
      @close="showCropper = false"
    />
  </ValidationObserver>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { CountriesAutocomplete } from '@/components/Inputs/selects';
import {
  FormRadio,
  FormText,
  FormDate,
  FormSelect,
  FormUploader,
  FormNumber,
} from '@/components/Inputs';
import { FadeTransition } from 'vue2-transitions';
import ImageCropper from '@/components/ImageCropper.vue';

extend('required', required);
extend('email', email);

export default {
  components: {
    FadeTransition,
    FormRadio,
    FormText,
    FormDate,
    FormSelect,
    FormUploader,
    FormNumber,
    CountriesAutocomplete,
    ImageCropper,
  },
  props: {
    formData: {
      type: Object,
      default: () => ({
        details: {
          passports: [],
          addresses: [],
        },
      }),
    },
  },
  data: () => ({
    form: {},
    entry_visa: false,
    no_gnib: true,
    no_passport: true,
    flightFileURL: null,
    profileFileURL: null,
    entryVisaFileURL: null,
    mkt_sources: [],
    showImgTooltip: false,
    showCropper: false,
    imageType: null,
  }),
  watch: {
    formData: {
      immediate: true,
      handler(data) {
        this.form = { ...data };
        if (this.form.file?.url) {
          this.flightFileURL = this.form.file.url;
        }
        if (this.form.profile_file?.url) {
          this.profileFileURL = this.form.profile_file.url;
        }
        if (this.form.entry_visa_file?.url) {
          this.entry_visa = true;
          this.entryVisaFileURL = this.form.entry_visa_file.url;
        }
        if (typeof this.form.details.no_gnib !== 'undefined') {
          this.no_gnib = this.form.details.no_gnib;
        }
        if (typeof this.form.details.no_passport !== 'undefined') {
          this.no_passport = this.form.details.no_passport;
        }
      },
    },
    no_gnib: {
      handler(val) {
        this.form.details.no_gnib = val;
      },
    },
    no_passport: {
      handler(val) {
        this.form.details.no_passport = val;
        if (val) this.form.details.passports = [];
        else {
          this.form.details.passports = [
            {
              passport_id: null,
              number: null,
              country_id: null,
              issue_date: null,
              expiration_date: null,
              file: null,
            },
          ];
        }
      },
    },
  },
  methods: {
    validate(callback) {
      return this.$refs.form.validate().then((res) => {
        let onlyStep = false;

        if (this.form.details.payments_by_agent
        && (!this.form.details.exam || (this.form.details.exam && this.form.details.exam.result))) {
          onlyStep = true;
        }

        if (!this.no_passport) {
          let canContinue = true;
          if (!this.form.details.passports[0].file && !this.form.passport_file) {
            this.fireError(this.$lang('first_step.errors.passport.file'));
            canContinue = false;
          }
          if (!this.form.details.passports[0].number) {
            this.fireError(this.$lang('first_step.errors.passport.number'));
            canContinue = false;
          }
          if (!this.form.details.passports[0].country_id) {
            this.fireError(this.$lang('first_step.errors.passport.country'));
            canContinue = false;
          }
          if (!this.form.details.passports[0].issue_date) {
            this.fireError(this.$lang('first_step.errors.passport.issue_date'));
            canContinue = false;
          }
          if (!this.form.details.passports[0].expiration_date) {
            this.fireError(this.$lang('first_step.errors.passport.expiration_date'));
            canContinue = false;
          }
          if (!canContinue) return false;
        }

        callback(res, this.form, onlyStep ? 2 : 1);
        return res;
      });
    },
    onEntryVisaFileUploaded(file) {
      this.form.entry_visa_file = file;
      this.entryVisaFileURL = URL.createObjectURL(file);
    },
    onFlightUploaded(file) {
      this.form.flight_file = file;
      this.flightFileURL = URL.createObjectURL(file);
    },
    onChangeFlightStatus(status) {
      this.form.details.flight_status = status;
      this.$forceUpdate();
    },

    // Image manipulation
    imageSelected(imageFile) {
      const imageURL = URL.createObjectURL(imageFile);
      this.form.profile_file = imageFile;
      this.profileFileURL = imageURL;
      this.showCropper = true;
      this.imageType = imageFile.type;
    },
    imageCropped(blob) {
      const imageURL = URL.createObjectURL(blob);

      // Convert blob to file
      const file = blob;
      file.lastModifiedDate = new Date();
      file.name = 'image';

      this.form.profile_file = file;
      this.profileFileURL = imageURL;
    },
  },
};
</script>

<style lang="scss" scoped>
.flightFileContainer {
  position: relative;
  width: auto;
  margin-right: auto;
  max-height: 150px;
  max-width: 150px;

  .md-button {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.md-label {
  font-weight: 600;
  margin: 1em 0 .5em 0;
}
span.asterisk {
  color: red;
  font-weight: 800;
  opacity: 1;
  // background: white;
  // border-radius: 50%;
  // height: 10px;
  // width: 10px;
  // margin-bottom: 5px;
  // margin-left: 5px;
}
::v-deep .md-switch.text-black label{
  color: black !important;
  font-weight: 600;
}
@media (max-width: 768px) {
  .d-flex.flex-column-sm {
    flex-direction: column;
  }
}
@media (max-width: 960px) {
  .imgSelectorDetails {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    font-size: 10px;
  }
}
@media (max-width: 991px) {
  .imgSelectorDetails {
    font-size: 9px;
    line-height: 12px;
  }
}

h5 {
  &.withTooltip {
    position: relative;
  }
  .md-sm i {
    font-size: 1em !important;
  }
  .tooltipImg {
    position: absolute;
    background: white;
    z-index: 10000;
    top: 25px;
    left: 0;
    border-radius: .5em;
    overflow: hidden;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.75);
  }
}
</style>
