<template>
  <div class="w-100">
    <md-card
      v-if="formInvalid"
      class="w-100 text-center"
    >
      <md-card-header class="py-5">
        <md-icon class="md-size-3x text-warning">
          warning_amber
        </md-icon>
        <h3 class="title">
          {{ $lang('not_found.title') }}
        </h3>
        <h5 class="category">
          {{ $lang('not_found.subtitle') }}
        </h5>
      </md-card-header>
    </md-card>
    <simple-wizard
      v-else-if="loaded"
      class="w-100"
    >
      <template slot="header">
        <h3 class="title">
          {{ $lang('new_form.title') }}
        </h3>
        <h5 class="category">
          {{ $lang('new_form.subtitle') }}
        </h5>
      </template>
      <wizard-tab
        v-if="form.status !== 'Approved'"
        :before-change="() => validateStep('step1')"
      >
        <template slot="label">
          {{ $lang('first_step.title') }}
        </template>
        <FirstStep
          ref="step1"
          :form-data="form"
        />
      </wizard-tab>
      <wizard-tab
        v-if="form.details.exam && !form.details.exam.result"
        :before-change="() => validateStep('examStep')"
      >
        <template slot="label">
          {{ $lang('exam_step.title') }}
        </template>
        <ExamStep
          ref="examStep"
          :form-data="form"
        />
      </wizard-tab>
      <wizard-tab
        v-if="!form.details.payments_by_agent"
        :before-change="() => validateStep('step2')"
      >
        <template slot="label">
          {{ $lang('second_step.title') }}
        </template>
        <SecondStep
          ref="step2"
          :form-data="form"
          @onUploaded="getForm"
        />
      </wizard-tab>
    </simple-wizard>
  </div>
</template>

<script>
import { SimpleWizard, WizardTab } from '@/components';
import moment from 'moment';
import Swal from 'sweetalert2';
import SecondStep from './steps/SecondStep.vue';
import FirstStep from './steps/FirstStep.vue';
import ExamStep from './steps/ExamStep.vue';
import '@/utils/ucwords';

export default {
  components: {
    SimpleWizard,
    WizardTab,
    FirstStep,
    SecondStep,
    ExamStep,
  },
  data() {
    return {
      form: {
        details: {
          package_total: 0,
          passports: [],
          addresses: [],
          package_payments: [],
          package_items: [],
        },
        flight_file: null,
        passport_file: null,
        entry_visa_file: null,
        profile_file: null,
      },
      loaded: false,
      showSaveButton: false,
      formInvalid: false,
    };
  },
  mounted() {
    this.getForm();
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate(this.onStepValidated)
        .catch((err) => console.error(err));
    },
    isFullyAnswered(incompleteFields = []) {
      const fields = [
        'first_name',
        'last_name',
        'country',
        'gender',
        'emergency_contact',
        'emergency_contact_name',
        'date_of_birth',
        'phone',
        'mkt_source_id',
        'passports',
        'addresses',
        'package_payments',
        'flight',
        'gnib',
      ];

      let isFull = true;
      const { details } = this.form;

      // eslint-disable-next-line no-restricted-syntax
      for (const field of fields) {
        switch (field) {
          case 'flight':
            if (['living_in_ireland', 'no_ticket_yet'].includes(details.flight_status)) break;
            if (
              !details.flight.flight_code
              || !details.flight.departure_date
              || !details.flight.arrival_date
              || !details.flight.airline
            ) {
              isFull = false;

              if (!details.flight.flight_code) incompleteFields.push(this.$lang('first_step.fields.flight_code'));
              if (!details.flight.departure_date) incompleteFields.push(this.$lang('first_step.fields.flight_departure_date'));
              if (!details.flight.arrival_date) incompleteFields.push(this.$lang('first_step.fields.flight_arrival_date'));
              if (!details.flight.airline) incompleteFields.push(this.$lang('first_step.fields.flight_airline'));
            }
            break;
          case 'gnib':
            if (details.no_gnib) break;
            if (
              !details.gnib.reg_number
              || !details.gnib.issue_date
              || !details.gnib.expiration_date
              || !details.gnib.stamp
            ) {
              isFull = false;
              if (!details.gnib.reg_number) incompleteFields.push(this.$lang('first_step.fields.irp_reg_number'));
              if (!details.gnib.issue_date) incompleteFields.push(this.$lang('first_step.fields.irp_issue_date'));
              if (!details.gnib.expiration_date) incompleteFields.push(this.$lang('first_step.fields.irp_exp_date'));
              if (!details.gnib.stamp) incompleteFields.push(this.$lang('first_step.fields.irp_stamp'));
            }
            break;
          case 'passports':
            if (details.no_passport) break;
            if (
              !details.passports.length
              || !details.passports[0].number
              || !details.passports[0].country
              || !details.passports[0].issue_date
              || !details.passports[0].expiration_date
            ) {
              isFull = false;
              if (!details.passports[0].number) incompleteFields.push(this.$lang('first_step.fields.passport_number'));
              if (!details.passports[0].country) incompleteFields.push(this.$lang('first_step.fields.passport_country'));
              if (!details.passports[0].issue_date) incompleteFields.push(this.$lang('first_step.fields.passport_issued_date'));
              if (!details.passports[0].expiration_date) incompleteFields.push(this.$lang('first_step.fields.passport_exp_date'));
            }
            break;
          case 'addresses':
            if (
              !details.addresses.length
              || !details.addresses[0].address
              || !details.addresses[0].city
              || !details.addresses[0].country
              || !details.addresses[0].type
            ) {
              isFull = false;
              if (!details.addresses[0].address) incompleteFields.push(this.$lang('first_step.fields.address'));
              if (!details.addresses[0].city) incompleteFields.push(this.$lang('first_step.fields.address_city'));
              if (!details.addresses[0].country) incompleteFields.push(this.$lang('first_step.fields.address_country'));
            }
            break;
          case 'package_payments': {
            if (details.payments_by_agent) break;
            if (!details.package_payments.length) {
              incompleteFields.push(this.$lang('second_step.errors.expected_payments'));
              isFull = false;
            }
            break;
          }
          default:
            if (!details[field]) {
              isFull = false;
              incompleteFields.push(field.split('_').join(' ').ucwords());
            }
            break;
        }
      }

      return isFull;
    },
    // eslint-disable-next-line no-unused-vars
    onStepValidated(validated, model, step = null) {
      if (validated) {
        this.form = model;

        if (step && step === 2) {
          this.saveForm(() => {
            const incompleteFields = [];
            if (this.isFullyAnswered(incompleteFields)) {
              Swal.fire({
                allowOutsideClick: false,
                showConfirmButton: false,
                icon: 'success',
                text: this.$lang('success_msg.fully_answered'),
              });
            } else {
              Swal.fire({
                allowOutsideClick: false,
                showConfirmButton: false,
                icon: 'success',
                html: this.$lang('success_msg.fully_answered')
                  .replace('$FIELDS$', incompleteFields.join('<br>')),
              });
            }
          });
        } else this.saveForm();
      } else {
        this.fireError(this.$lang('errors.complete_fields'));
      }
    },
    async getForm() {
      this.formInvalid = false;
      this.axios
        .get(`${process.env.VUE_APP_SERVER}student_forms/${this.$route.params.token}`)
        .then(async ({ data }) => {
          this.form = data.data;

          this.loaded = true;
        })
        .catch((err) => {
          console.error(err);
          this.formInvalid = true;
        });
    },
    saveForm(callback = null) {
      const finalData = { ...this.form };

      // Convert Flight Dates to String to avoid any convertion by middlewares
      if (finalData.details.flight) {
        if (finalData.details.flight.departure_date) {
          finalData.details.flight.departure_date = moment(finalData.details.flight.departure_date)
            .format('YYYY-MM-DD HH:mm');
        }
        if (finalData.details.flight.arrival_date) {
          finalData.details.flight.arrival_date = moment(finalData.details.flight.arrival_date)
            .format('YYYY-MM-DD HH:mm');
        }
      }

      if (finalData.details.passports) {
        finalData.passport_file = finalData.details.passports.map((x) => x.file);
        finalData.details.passports = finalData.details.passports.map((x) => ({
          passport_id: x.passport_id,
          country_id: x.country_id,
          country: x.country,
          expiration_date: x.expiration_date,
          issue_date: x.issue_date,
          number: x.number,
        }));
      }

      const sanitizedData = this.paramsSanitization(finalData);

      const formData = new FormData();
      formData.append('details', JSON.stringify(sanitizedData.details));
      formData.append('flight_file', finalData.flight_file);
      formData.append('profile_file', finalData.profile_file);
      formData.append('passport_file', finalData.passport_file[0]);
      formData.append('entry_visa_file', finalData.entry_visa_file);

      this.guestRequest(
        `student_forms/${this.$route.params.token}/accept`,
        'put',
        formData,
        () => {
          if (callback) callback();
          else this.fireSuccess(this.$lang('success_msg.form_saved'));
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .md-card-header {
  h3.title {
    color: #3c4858 !important;
    margin-top: 10px;
  }
  h5.category {
    margin-bottom: 0;
  }
  small {
    display: inline-block;
    margin-bottom: 20px;
  }
}
</style>
