import { render, staticRenderFns } from "./uploadFileModal.vue?vue&type=template&id=b4888fb6&scoped=true&"
import script from "./uploadFileModal.vue?vue&type=script&lang=js&"
export * from "./uploadFileModal.vue?vue&type=script&lang=js&"
import style0 from "./uploadFileModal.vue?vue&type=style&index=0&id=b4888fb6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4888fb6",
  null
  
)

export default component.exports