<template>
  <ValidationObserver ref="form">
    <div
      v-if="!loaded"
      style="text-align: center"
    >
      <Loader />
    </div>
    <form
      v-else
      @submit.prevent="validate"
    >
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="alert alert-warning">
            <div class="d-flex">
              <md-icon class="text-white ml-4 mr-4">
                warning_amber
              </md-icon>
              <p class="font-weight-bold w-100">
                {{ $lang('second_step.pay_before') }} {{ dateFormat(dateToPay) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="formApproved"
        class="md-layout"
      >
        <div class="md-layout-item">
          <h2>
            <span class="text-success">{{ $lang('second_step.paid') }}</span>:
            <span>€ {{ paid.toFixed(2) }}</span>
            -
            <span class="text-danger">{{ $lang('second_step.due') }}</span>:
            <span>€ {{ due.toFixed(2) }}</span>
          </h2>
        </div>
        <md-button
          class="md-primary w-100 md-sm"
          @click="newPayment"
        >
          <md-icon>add</md-icon>
          {{ $lang('second_step.new_payment') }}
        </md-button>
      </div>

      <template v-if="!isTablet">
        <div
          class="md-layout"
        >
          <div class="md-layout-item md-size-100">
            <md-table>
              <md-table-row>
                <md-table-cell
                  style="width: 20%"
                >
                  {{ $lang('second_step.expected_date') }}
                </md-table-cell>
                <md-table-cell v-if="formApproved">
                  {{ $lang('second_step.payment_date') }}
                </md-table-cell>
                <md-table-cell>
                  {{ $lang('second_step.amount') }}
                </md-table-cell>
                <md-table-cell>
                  {{ $lang('second_step.method') }}
                </md-table-cell>
                <md-table-cell
                  v-if="formApproved"
                  style="width: 10%"
                >
                  {{ $lang('second_step.status') }}
                </md-table-cell>
                <md-table-cell>
                  <md-button
                    class="md-just-icon md-round md-primary pull-right"
                    @click="newPayment"
                  >
                    <md-icon>add</md-icon>
                    <md-tooltip>{{ $lang('second_step.new_payment') }}</md-tooltip>
                  </md-button>
                </md-table-cell>
              </md-table-row>

              <md-table-row
                v-for="(item, index) in form.details.package_payments"
                :key="item.key"
              >
                <md-table-cell>
                  <form-date
                    v-model="item.expected_date"
                    name="expected_date"
                    :label="`${$lang('second_step.select_date')}*`"
                    rules="required"
                    :disabled="item.status && ['Paid','Void'].includes(item.status)"
                  />
                </md-table-cell>
                <md-table-cell v-if="formApproved">
                  <form-date
                    v-model="item.final_payment_date"
                    :label="`${$lang('second_step.select_date')}*`"
                    disabled
                  />
                </md-table-cell>
                <md-table-cell>
                  <FormNumber
                    v-model="item.amount"
                    currency
                    name="amount"
                    icon="euro"
                    :label="`${$lang('second_step.amount')}*`"
                    rules="required|minCurrencyValue:0"
                    :disabled="item.status && ['Paid','Void'].includes(item.status)"
                    @input="totalPaymentsCalc"
                  />
                </md-table-cell>
                <md-table-cell>
                  <form-select
                    v-model="item.payment_method_id"
                    name="method"
                    rules="required"
                    :label="`${$lang('second_step.select_method')}*`"
                    :options="methods"
                    :disabled="item.status && ['Paid','Void'].includes(item.status)"
                  />
                </md-table-cell>
                <md-table-cell v-if="formApproved">
                  <md-badge
                    v-if="item.amount < 0"
                    class="md-square md-danger"
                    :md-content="$lang('second_step.refunded')"
                  />
                  <md-badge
                    v-else
                    :class="{
                      'md-info': item.status === 'Waiting for Approval',
                      'md-danger': item.status === 'Overdue',
                      'md-default': item.status === 'Void',
                      'md-warning': item.status === 'Unpaid',
                      'md-primary': item.status === 'Rejected',
                      'md-success': item.status === 'Paid',
                    }"
                    class="md-square"
                    :md-content="$lang('second_step.refunded')[item.status]"
                  />
                </md-table-cell>
                <md-table-cell>
                  <md-button
                    v-if="formApproved && ['Unpaid','Overdue'].includes(item.status) && !item.file"
                    class="md-simple md-icon-button md-info"
                    @click="uploadFile(item)"
                  >
                    <md-icon>cloud_upload</md-icon>
                    <md-tooltip>{{ $lang('second_step.proof_button') }}</md-tooltip>
                  </md-button>

                  <md-button
                    v-if="formApproved && ['Unpaid','Overdue'].includes(item.status) && item.file"
                    class="md-simple md-icon-button md-success"
                  >
                    <md-icon>history</md-icon>
                    <md-tooltip>{{ $lang('second_step.processing_payment') }}</md-tooltip>
                  </md-button>

                  <md-button
                    v-if="index > 0
                      && (!item.status || !['Paid','Void'].includes(item.status))
                      && !item.file"
                    class="md-simple md-icon-button md-danger"
                    @click="deletePayment(index)"
                  >
                    <md-icon>close</md-icon>
                    <md-tooltip>{{ $lang('second_step.delete') }}</md-tooltip>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>

        <div class="md-layout mt-5">
          <div class="md-layout-item md-size-100">
            <md-table>
              <md-table-row>
                <md-table-cell>
                  {{ $lang('second_step.total_package') }}
                </md-table-cell>
                <md-table-cell>
                  {{ $lang('second_step.total_payments') }}
                </md-table-cell>
              </md-table-row>

              <md-table-row>
                <md-table-cell>
                  <FormNumber
                    currency
                    icon="euro"
                    :value="total.toFixed(2)"
                    disabled
                  />
                </md-table-cell>
                <md-table-cell>
                  <FormNumber
                    currency
                    icon="euro"
                    :value="totalPayments"
                    disabled
                  />
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
      </template>

      <template v-else>
        <div
          v-for="(item, index) in form.details.package_payments"
          :key="item.key"
          class="c-payment"
        >
          <!-- TAGS -->
          <div class="d-flex">
            <md-badge
              v-if="item.amount < 0"
              class="md-square md-danger w-100 p-0"
              :md-content="$lang('second_step.refunded')"
            />
            <md-badge
              v-else
              :class="{
                'md-info': item.status === 'Waiting for Approval',
                'md-danger': item.status === 'Overdue',
                'md-default': !item.status || item.status === 'Void',
                'md-warning': item.status === 'Unpaid',
                'md-primary': item.status === 'Rejected',
                'md-success': item.status === 'Paid',
              }"
              class="md-square w-100 p-0"
              :md-content="`${$lang('second_step.payment')}
              #${index+1}${item.status ? ` - ${$lang('second_step.statuses')[item.status]}` : ''}`"
            />
          </div>
          <!-- BUTTONS -->
          <div class="d-flex buttons mb-4">
            <md-badge
              v-if="formApproved && ['Unpaid','Overdue'].includes(item.status) && item.file"
              class="md-square md-success w-100 p-0 mt-2"
              :md-content="$lang('second_step.processing_payment')"
            />

            <md-button
              v-if="formApproved && ['Unpaid','Overdue'].includes(item.status) && !item.file"
              class="md-sm md-info"
              @click="uploadFile(item)"
            >
              <md-icon>cloud_upload</md-icon>
              {{ $lang('second_step.proof_button') }}
            </md-button>

            <md-button
              v-if="index > 0
                && (!item.status || !['Paid','Void'].includes(item.status))
                && !item.file"
              class="md-sm  md-danger"
              @click="deletePayment(index)"
            >
              <md-icon>close</md-icon>
              {{ $lang('second_step.delete') }}
            </md-button>
          </div>

          <!-- INPUTS -->
          <div>
            <FormDate
              v-model="item.expected_date"
              name="expected_date"
              :label="`${$lang('second_step.payment_expected_date')}*`"
              rules="required"
              :disabled="item.status && ['Paid','Void'].includes(item.status)"
            />
            <FormDate
              v-model="item.final_payment_date"
              :label="$lang('second_step.payment_done_date')"
              disabled
            />

            <FormNumber
              v-model="item.amount"
              :label="`${$lang('second_step.amount')}*`"
              icon="euro"
              rules="required|minCurrencyValue:0"
              :disabled="item.status && ['Paid','Void'].includes(item.status)"
              currency
              @input="totalPaymentsCalc"
            />
            <FormSelect
              v-model="item.payment_method_id"
              name="method"
              rules="required"
              :label="`${$lang('second_step.method')}*`"
              icon="account_balance"
              :options="methods"
              :disabled="item.status && ['Paid','Void'].includes(item.status)"
            />
          </div>
        </div>

        <div class="d-flex pt-5">
          <FormNumber
            currency
            class="w-100"
            :label="$lang('second_step.total_package')"
            icon="euro"
            :value="total.toFixed(2)"
            disabled
          />
          <FormNumber
            currency
            class="w-100"
            :label="$lang('second_step.total_payments')"
            icon="euro"
            :value="totalPayments"
            disabled
          />
        </div>
      </template>
    </form>

    <UploadFileModal
      v-if="form.programme && selPayment"
      :student-id="form.programme.student_id"
      :package-payment-id="selPayment.package_payment_id"
      @close="selPayment = null"
      @onUploaded="$emit('onUploaded')"
    />
  </ValidationObserver>
</template>
<script>
import Loader from '@/components/loader';
import { extend } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required } from 'vee-validate/dist/rules';
import minCurrencyValue from '@/core/validators/minCurrencyValue';
import {
  FormSelect, FormDate, FormNumber,
} from '@/components/Inputs';
import moment from 'moment';
import mobileChecker from '@/mixins/mobileChecker';
import downloadFile from '@/utils/downloadFile';
import UploadFileModal from './uploadFileModal.vue';

extend('required', required);
extend('minCurrencyValue', minCurrencyValue);

export default {
  components: {
    FormSelect,
    FormNumber,
    FormDate,
    Loader,
    UploadFileModal,
  },
  mixins: [
    mobileChecker,
  ],
  props: {
    formData: {
      type: Object,
      default: () => ({
        details: {
          package_total: 0,
          passports: [],
          addresses: [],
        },
      }),
    },
  },
  data() {
    return {
      form: {},
      methods: [],
      totalPayments: 0,
      total: 0,
      loaded: false,
      selPayment: null,
    };
  },
  computed: {
    paid() {
      return this.form.details.package_payments
        .reduce((a, b) => a + (b.status && b.status === 'Paid' ? parseFloat(b.total) : 0), 0);
    },
    due() {
      return this.total - this.paid;
    },
    formApproved() {
      return ['Approved'].includes(this.form.status);
    },
    dateToPay() {
      const date = moment(this.form.details.start_date);

      if (['1st Visa', 'EU'].includes(this.form.details.visa_type)) {
        date.subtract(4, 'weeks');
      } else {
        date.subtract(1, 'weeks');
      }

      return date;
    },
  },
  watch: {
    formData: {
      immediate: true,
      handler(val) {
        this.form = val;
        this.total = val.details.package_total;
        this.totalPaymentsCalc();
      },
    },
  },
  async mounted() {
    await this.getMethods();

    this.loaded = true;
  },
  methods: {
    validate(callback) {
      if (this.total > this.totalPayments) {
        this.fireError(this.$lang('second_step.errors.not_enough_payments'));
        return false;
      }
      if (this.total < this.totalPayments) {
        this.fireError(this.$lang('second_step.errors.too_much_payments'));
        return false;
      }

      let validObjects = true;
      let validDates = true;

      this.form.details.package_payments.forEach((x) => {
        if (!x.expected_date || !x.amount || !x.payment_method_id) validObjects = false;

        if (this.dateToPay.isBefore(x.expected_date)) {
          validDates = false;
        }
      });

      if (!validObjects) {
        this.fireError(this.$lang('second_step.errors.complete_columns'));
        return false;
      }
      if (!validDates) {
        this.fireError(`${this.$lang('second_step.errors.invalid_dates')} 
          ${this.dateFormat(this.dateToPay)}`);
        return false;
      }

      return this.$refs.form.validate().then((res) => {
        callback(res, this.form, 2);
        return res;
      });
    },
    newPayment() {
      this.form.details.package_payments.push({
        key: Math.random() * Date.now(),
        expected_date: moment().add(1, 'day'),
        amount: (this.total - this.totalPayments).toFixed(2),
        payment_method_id: 1,
      });

      this.totalPaymentsCalc();
    },
    getMethods() {
      return new Promise((resolve) => {
        this.guestRequest('payment_methods?per_page=1000', 'get', null, ({ data }) => {
          this.methods = data.data.map((x) => {
            const aux = x;
            aux.id = x.payment_method_id;
            aux.name = `${x.name} ${x.help_text ? `(${x.help_text})` : ''}`;
            return aux;
          });
          resolve();
        });
      });
    },

    onDownloadInvoice(item) {
      this.request(this.$API.SCHEDULED_PAYMENTS + item.package_payment_id, 'download', null, ({ data }) => {
        const filename = `${this.firstData.studentData.student_number}_${moment(item.payment_date).format('DD-MM-YYYY')}.pdf`;
        downloadFile(data, filename);
      });
    },
    deletePayment(index) {
      this.form.details.package_payments.splice(index, 1);
      this.totalPaymentsCalc();
    },
    totalPaymentsCalc() {
      if (this.form.details.package_payments.length) {
        this.totalPayments = this.form.details.package_payments.reduce((a, b) => {
          if (!b.status || (b.status && b.status !== 'Void')) {
            return a + (b.amount ? parseFloat(b.amount) : 0);
          }
          return a + 0;
        }, 0);
      } else {
        this.totalPayments = 0;
      }

      this.totalPayments = this.totalPayments.toFixed(2);
    },

    uploadFile(item) {
      this.selPayment = item;
    },
  },
};
</script>
<style scoped lang="scss">
  .alert {
    z-index: 900 !important;
  }
  .md-form-group .md-select { padding-left: 10px; }
  input.md-input { width: 100%; }
  .md-table-cell {
    padding: 0px 5px;
    border: unset !important;
  }
  .md-table-row:first-child .md-table-cell {
    border-bottom: 1px solid #ccc !important;
  }

  .md-badge {
    position: relative !important;
    height: auto;
  }
  .md-badge.md-danger {
    background: #f44336 !important;
    color: #fff;
  }
  .md-badge.md-default {
    background: #686565 !important;
    color: #fff;
  }
  .md-badge.md-warning {
    background: #ff9800 !important;
    color: #fff;
  }
  .md-badge.md-success {
    background: #4caf50 !important;
    color: #fff;
  }
  .md-badge.md-info {
    background: #00bcd4 !important;
    color: #fff;
  }
  .md-badge.md-primary {
    background: #9c27b0 !important;
    color: #fff;
  }
  ::v-deep .vue-modal {
    z-index: 1000 !important;
  }
  ::v-deep .vue-modal .vue-modal-dialog {
    margin-top: 100px !important;
  }
  ::v-deep .md-table {
    overflow: visible;

    table, .md-table-content {
      overflow: visible;
    }
  }

  @media (max-width: 1024px) {
    h2 {
      font-size: 1.25em;
    }

    .c-payment {
      &:not(:last-of-type) {
        border-bottom: 1px solid #ccc;
      }

      padding: 25px 10px;

      h4 {
        margin: 0;
      }

      .md-badge {
        position: relative !important;
        left: 0 !important;
        right: 0 !important;
      }

      .d-flex.buttons {
        @media(max-width: 500px) {
          flex-direction: column;
        }
        > * {
          flex: 1;
          margin-bottom: 0;
        }
      }
    }
  }
</style>
