export default {
  data: () => ({
    screen_width: 0,
  }),
  created() {
    const self = this;
    self.screen_width = window.innerWidth;
    window.addEventListener('resize', () => {
      self.screen_width = window.innerWidth;
    });
  },
  computed: {
    isMobile() {
      return this.screen_width <= 760;
    },
    isTablet() {
      return this.screen_width <= 1024;
    },
  },
};
