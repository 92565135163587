<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <ul class="exam">
        <li
          v-for="(q, index) of questions"
          :key="index"
          class="question"
        >
          #{{ index+1 }} - <b>{{ q.question }}</b>

          <FormRadio
            v-model="q.selected_answer"
            class="answers"
            label="Answers"
            name="radio"
            :options="q.answers"
          />
        </li>
      </ul>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  FormRadio,
} from '@/components/Inputs';

export default {
  components: {
    FormRadio,
  },
  props: {
    formData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {},
  }),
  computed: {
    questions() {
      return JSON.parse(JSON.stringify(this.form.details.exam)).questions.map((x) => {
        x.answers = x.answers.map((y) => ({
          value: y.placement_answer_id,
          label: y.text,
        }));

        return x;
      });
    },
  },
  watch: {
    formData: {
      immediate: true,
      handler(data) {
        this.form = data;
      },
    },
  },
  methods: {
    validate(callback) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [index, q] of this.questions.entries()) {
        this.form.details.exam.questions[index].selected_answer = q.selected_answer;
      }

      return this.$refs.form.validate().then((res) => {
        callback(res, this.form, (this.form.details.payments_by_agent ? 2 : 1));
        return res;
      });
    },
  },
};
</script>

<style>

</style>
