<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      {{ $lang('file_modal.title') }}
    </template>
    <template slot="body">
      <ul
        class="subtitle"
        v-html="$lang('file_modal.file_info')"
      />

      <hr>

      <form-text
        v-model="form.title"
        name="title"
        icon="description"
        :label="$lang('file_modal.fields.title')"
        disabled
      />
      <form-uploader
        v-model="form.file"
        :max-size="5*1024"
        @error="onFileError"
      >
        <b
          v-if="form.file"
          class="text-success"
        >{{ form.file.name }}</b>
        <md-button
          class="md-info md-sm w-100"
        >
          {{ $lang('file_modal.fields.choose_file') }}
        </md-button>
      </form-uploader>
    </template>
    <template slot="footer">
      <md-button
        class="md-primary md-sm"
        @click="uploadForm"
      >
        <md-icon class="material-icons">
          save
        </md-icon>
        {{ $lang('file_modal.fields.button') }}
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { FormUploader, FormText } from '@/components/Inputs';

export default {
  components: {
    FormUploader,
    FormText,
  },
  props: {
    studentId: {
      type: Number,
      default: null,
    },
    packagePaymentId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    form: {
      package_payment_id: null,
      file_type_id: 3,
      student_id: null,
      title: this.$lang('file_modal.fields.default_title'),
      file: null,
      marketing_remark: null,
    },
  }),
  mounted() {
    this.form.package_payment_id = this.packagePaymentId;
    this.form.student_id = this.studentId;
    this.form.title += this.packagePaymentId;
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    uploadForm() {
      const formData = new FormData();
      formData.append('file', this.form.file);
      formData.append('title', this.form.title);
      formData.append('student_id', this.form.student_id);
      formData.append('package_payment_id', this.form.package_payment_id);
      formData.append('file_type_id', this.form.file_type_id);
      formData.append('marketing_remark', this.form.marketing_remark);

      this.guestRequest(`${this.$API.STUDENTS + this.form.student_id}/${this.$API.FILES}_guest`, 'post', formData, () => {
        this.fireSuccess(this.$lang('file_modal.success_msg'));
        this.$emit('onUploaded');
        this.close();
      });
    },
    onFileError(error) {
      this.fireError(error);
    },
  },
};
</script>
<style scoped>
.subtitle li {
  margin: 0;
}
.subtitle li a {
  font-weight: bold;
  color: #9c27b0 !important;
}
</style>
